<template>
    <div>
        <v-progress-linear :indeterminate="true" v-show="loading"></v-progress-linear>
        <v-row>
            <v-col :cols="8">
                <v-card>
                    <v-card-text>
                        <div ref="test" style="width: 100%; height:300px;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :cols="4">
                <v-card>
                    <v-card-text>
                        <div ref="service_count" style="width: 100%; height:300px;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :cols="4">
                <v-card>
                    <v-card-text>
                        <div ref="user_total1" style="width: 100%; height:390px;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :cols="4">
                <v-card>
                    <v-card-text>
                        <div ref="user_total2" style="width: 100%; height:390px;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <!-- 年龄结构 -->
            <v-col :cols="4">
                <v-card>
                    <v-card-text>
                        <div ref="users_age" style="width: 100%; height:390px;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
           
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import * as echarts from 'echarts';

    import api_sdata from '~/api/api_sdata';

    definePageMeta({
        layout: 'panel',
    })
    const website = useWebsiteStore()
    website.setTitle('首页数据')


    const service_count = ref<any>(null)

    const serviceCountInit = (data: any) => {
        const myChart = echarts.init(service_count.value)
        var option = {
            // xAxis: {
            //     type: 'category',
            //     data: ['活动人次', '志愿者人次', '预约人次']
            // },
            title: {
                text: ''
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['参与数据']
            },
            series: [
                {
                    name: '活动人次',
                    type: 'bar',
                    data: [data.event_count]
                },
                {
                    name: '志愿者人次',
                    type: 'bar',
                    data: [data.volunteer_count]
                },
                {
                    name: '预约人次',
                    type: 'bar',
                    data: [data.reservation_count]
                },
                // {
                //     data: [data.event_count, data.volunteer_count, data.reservation_count],
                //     type: 'bar'
                // }
            ]
        };
        myChart.setOption(option);
        myChart.resize({
        });
    }


    const users_age = ref<any>(null)

    interface AgeData {
        value: number,
        name: string
    }


    const userAgeInit = (data: any) => {

        var list = <AgeData[]>[]
        list.push({value: data.age_group_1, name: '20岁以下'})
        list.push({value: data.age_group_2, name: '20-40岁'})
        list.push({value: data.age_group_3, name: '40-60岁'})
        list.push({value: data.age_group_4, name: '60岁以上'})
        console.log(list)
        var option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: 40,
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: list
                }
            ]
            };
        const myChart = echarts.init(users_age.value)
        myChart.setOption(option);
        myChart.resize({
        });
    }


    const loading = ref<boolean>(true)

    const test = ref<any>(null)

    const echartsInit = (title: string,type: string, key: any[], data: any[]) => {
        const myChart = echarts.init(test.value)
        myChart.setOption({
            title: {
                text: title
            },
            tooltip: {},
            xAxis: {
                data: key
            },
            yAxis: {},
            series: [
                {
                name: '数据',
                type: '',
                data: data
                }
            ]
        });
        myChart.resize({
        });
    }

    const user_total1 = ref<any>(null)


    const user_totalInit1 = (title: string,data:any) => {
        const myChart = echarts.init(user_total1.value)
        myChart.setOption({
            title: {
                text: title,
                subtext: '用户总数：' + data.total_users + '人 党员：' + data.party_members + '人',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [
            {
                type: 'pie',
                data: [
                    {
                        value: data.party_members,
                        name: '党员'
                    },
                    {
                        value: parseInt(data.total_users) - parseInt(data.party_members),
                        name: '群众'
                    },
                ]
            }
        ]
        });
    }
    const user_total2 = ref<any>(null)

    const user_totalInit2 = (title: string,data:any) => {
        const myChart = echarts.init(user_total2.value)
        myChart.setOption({
            title: {
                text: title,
                subtext: '用户总数：' + data.total_users + '人',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left'
            },
            series: [
            {
            type: 'pie',
            data: [
                {
                    value: data.female_users,
                    name: '女性'
                },
                {
                    value: data.male_users,
                    name: '男性'
                },
                // {
                //     value: data.unknown_gender_users,
                //     name: '未知'
                // },
            ]
            }
        ]
        });
    }

    const event_sign = ref<any>(null)

    const event_signInit = (data: any[]) => {
        var source = []
        source.push(['人数', '活动'])
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            source.push([element.total_signups, element.event_name])
        }
        const myChart = echarts.init(event_sign.value)
        myChart.setOption({
            title: {
                text: '进行中的活动报名情况',
                left: 'center'
            },
            tooltip: {},
            dataset: {
                source: source
            },
            grid: { containLabel: true },
            xAxis: { name: '人数' },
            yAxis: { type: 'category' },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 1,
                max: 10,
                text: ['最多报名', '最低报名'],
                // Map the score column to color
                dimension: 0,
                inRange: {
                color: ['#65B581', '#FFCE34', '#FD665F']
                }
            },
            series: [
                {
                type: 'bar',
                encode: {
                    // Map the "amount" column to X axis.
                    x: '人数',
                    // Map the "product" column to Y axis
                    y: '活动'
                }
                }
            ]
        });
    }

    const volunteer_sign = ref<any>(null)

    const volunteer_signInit = (data: any[]) => {
        var total_signups = []
        var pending_verification = []
        var verified = []
        // total_signups.push(['人数', '活动'])
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            total_signups.push([element.total_signups, element.event_name])
            pending_verification.push([element.pending_verification, element.event_name])
            verified.push([element.verified, element.event_name])
        }
        const myChart = echarts.init(volunteer_sign.value)
        myChart.setOption({
            title: {
                text: '志愿者活动-进行中的报名人数',
                left: 'center'
            },
            tooltip: {},

            grid: { containLabel: true },
            xAxis: { name: '人数' },
            yAxis: { type: 'category' },
            visualMap: {
                orient: 'horizontal',
                left: 'center',
                min: 1,
                max: 10,
                text: ['最多报名', '最低报名'],
                // Map the score column to color
                dimension: 0,
                inRange: {
                color: ['#65B581', '#FFCE34', '#FD665F']
                }
            },
            series: [
                {
                    name: "总报名",
                    type: 'bar',
                    data: total_signups,
                    encode: {
                        // Map the "amount" column to X axis.
                        x: '人数',
                        // Map the "product" column to Y axis
                        y: '活动'
                    }
                },
                {
                    name: "待人数",
                    type: 'bar',
                    data: pending_verification,
                    encode: {
                        // Map the "amount" column to X axis.
                        x: '人数',
                        // Map the "product" column to Y axis
                        y: '活动'
                    }
                },
                {
                    name: "已核销",
                    type: 'bar',
                    data: verified,
                    encode: {
                        // Map the "amount" column to X axis.
                        x: '人数',
                        // Map the "product" column to Y axis
                        y: '活动'
                    }
                }
            ]
        });
    }

    const data = ref<any>({})

    const getData =  () => {
        loading.value = true
        api_sdata.HomeDate().then((res) => {
            data.value = res.data
            console.log(data.value)
            var week = []
            var week_data = []
            var user_last_week = data.value.user_last_week
            for (let index = 0; index < user_last_week.length; index++) {
                const element = user_last_week[index];
                week.push(element.registration_date)
                week_data.push(element.new_users_count)
            }
            echartsInit('最近一周新增用户数','line', week, week_data)
            user_totalInit1('党员分布',data.value.user_total)
            user_totalInit2('用户结构',data.value.user_total)
            userAgeInit(data.value.users_age[0])
            serviceCountInit(data.value.service_count)
            // service_count
            loading.value = false
        })
    }

    const init = () => {
        getData()
       
    }


    if(process.client){
        onMounted(() => {
            init()
        })
    }

</script>
